import cogoToast from "cogo-toast"
import { useState } from "react"
import { Button, Spinner, Table } from "react-bootstrap"
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../../models/EvaluacionesReparaciones"
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi"

interface Props {
    evaluacionReparacionComponenteResumenEtapa: EvaluacionOrReparacionComponenteResumenEtapa,
    changeEvaulacionReparacionComponenteResumenEtapa: (evaulacionComponenteResumen: EvaluacionOrReparacionComponenteResumenEtapa) => void,
    showInicio: boolean,
    showAplica: boolean,
    showTermino: boolean,
    generalOnHide: () => void,
    evaluacionReparacionComponenteEtapaTareasApplyConst?: { nombreTarea: string, isConst: boolean }[],
    isTareaComplete: (nombreTarea: string) => boolean,
    isEvaluacionOrReparacionNotComplete?: boolean,
    detallesApi: DetallesEvaluacionReparacionApi
}

const EditEvaluacionReparacionComponenteResumen = ({
    evaluacionReparacionComponenteResumenEtapa: evaluacionComponenteResumenEtapa, changeEvaulacionReparacionComponenteResumenEtapa, showInicio, showAplica, showTermino, generalOnHide, evaluacionReparacionComponenteEtapaTareasApplyConst: evaluacionComponenteEtapaTareasApplyConst, isTareaComplete, isEvaluacionOrReparacionNotComplete: isEvaluacionNotComplete, detallesApi
}: Props) => {

    const [ isSaving, setIsSaving ] = useState<boolean>(false);
    const [ evaluacionComponenteResumenEtapaForm, setEvaluacionComponenteResumenEtapaForm ] = useState<{ check: boolean, disabled: boolean }[]>(
        (evaluacionComponenteResumenEtapa.tareas || []).map((tarea) => {
            let check = true;
            let disabled = false;
            if (showInicio && !showTermino) {
                // check = true;
                let breakCondition = 0;
                for (let i = 0; i < tarea.valores.length; i++) {
                    if ((tarea.valores[i].columna === 'Fin' || tarea.valores[i].columna === 'Fecha') && tarea.valores[i].valor) {
                        disabled = true;
                        breakCondition = breakCondition + 1;
                    } else if (tarea.valores[i].columna === 'SI/NO' && tarea.valores[i].valor && tarea.valores[i].valor === 'NO') {
                        disabled = true;
                        check = false;
                        breakCondition = breakCondition + 1;
                    }
                    if (breakCondition > 1) {
                        break;
                    }
                }
            }
            if (!showInicio && showTermino) {
                let isEditable = true;
                for (let i = 0; i < tarea.valores.length; i++) {
                    if (tarea.valores[i].columna === 'Inicio') {
                        if (!tarea.valores[i].valor) {
                            isEditable = false;
                        }
                        break;
                    }
                }
                if (isEditable) {
                    isEditable = isTareaComplete(tarea.nombreTarea);
                }
                if (!isEditable) {
                    disabled = true;
                    check = false;
                }
            }
            return { check: check, disabled: disabled }
        })
    );

    const [ tareasApply, setTareasApply ] = useState<{ check: boolean, disabled: boolean }[] | undefined>((): { check: boolean, disabled: boolean }[] | undefined => {
        if (showInicio || showAplica) {
            return evaluacionComponenteResumenEtapa.tareas?.map((tarea, index) => {
                let check = true;
                let disabled = true;
                if (isEvaluacionNotComplete && (index >= 0 && index < 3)) {
                    check = false;
                } else if (!evaluacionComponenteEtapaTareasApplyConst?.find((tarea2) => tarea2.nombreTarea === tarea.nombreTarea)?.isConst) {
                    for (let i = 0; i < tarea.valores.length; i++) {
                        if (tarea.valores[i].columna === 'SI/NO') {
                            if (!showInicio || (showInicio && !evaluacionComponenteResumenEtapaForm[index].check)) {
                                disabled = false;
                                if (tarea.valores[i].valor === 'NO') {
                                    check = false;
                                }
                            }
                            break;
                        }
                    }
                }
                return { check: check, disabled: disabled }
            });
        }
    });

    const submitResumen = () => {
        setIsSaving(true);
        let newEvaluacionComponenteResumenEtapa = evaluacionComponenteResumenEtapa;
        const tareas = newEvaluacionComponenteResumenEtapa.tareas;
        const nombreColumna = showInicio ? 'Inicio' : showTermino ? 'Fin' : showAplica ? 'Fecha' : '';
        if (tareas) {
            evaluacionComponenteResumenEtapaForm.forEach((tarea, index) => {
                for (let e = 0; e < tareas[index].valores.length; e++) {
                    if (tareasApply && tareas[index].valores[e].columna === 'SI/NO') {
                        tareas[index].valores[e].valor = tareasApply[index].check ? 'SI' : 'NO';
                    } else if (tareas[index].valores[e].columna === nombreColumna) {
                        const check = showAplica && !showInicio && tareasApply ? tareasApply[index].check : tarea.check;
                        if (tareas[index].nombreTarea !== 'Aprobación jefe de taller' && check && !tareas[index].valores[e].valor) {
                            tareas[index].valores[e].valor = 'NUEVO';
                        } else if (!check && tareas[index].valores[e].valor) {
                            tareas[index].valores[e].valor = 'ELIMINAR';
                        }
                        break;
                    }
                }
            });
            newEvaluacionComponenteResumenEtapa.tareas = tareas;
        }
        detallesApi.putEvaluacionOrReparacionComponenteResumen(newEvaluacionComponenteResumenEtapa)
            .then((response) => {
                if (response.data) {
                    changeEvaulacionReparacionComponenteResumenEtapa(response.data);
                    cogoToast.success('Registro de actividades actualizado');
                    generalOnHide();
                } else {
                    cogoToast.error('No fue posible actualizar el registro');
                }
                setIsSaving(false);                
            }).catch(() => {
                cogoToast.error('No fue posible actualizar el registro');
                setIsSaving(false);
            })
    }

    return (
        <>
            <Table bordered size = 'sm'>
                <thead>
                    <tr>
                        <th>Actividad</th>
                            {showInicio && <th><div className = "centerTextCell">¿Aplica?</div></th>}
                        <th>
                            <div className = "centerTextCell">
                                ¿{showAplica ? 'Aplica': showInicio && !showTermino ? 'Iniciada' : !showInicio && showTermino && 'Terminada'}?
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {evaluacionComponenteResumenEtapa.tareas?.map((tarea, index) => (
                        <tr>
                            <td>{tarea.nombreTarea}</td>
                            {(showAplica || showInicio) && tareasApply &&
                                <td>
                                    <div className = "centerTextCell">
                                        <input
                                            className = "largeCheckBox"
                                            type = "checkbox"
                                            checked = {tareasApply[index].check}
                                            disabled = {tareasApply[index].disabled}
                                            onChange = {() => {
                                                let newFormApplies = tareasApply;
                                                let newFormChecks = evaluacionComponenteResumenEtapaForm;
                                                newFormApplies[index].check = !newFormApplies[index].check;
                                                if (newFormApplies[index].check) {
                                                    newFormChecks[index].disabled = false;
                                                } else {
                                                    newFormChecks[index].check = false;
                                                    newFormChecks[index].disabled = true;
                                                }
                                                setEvaluacionComponenteResumenEtapaForm([...newFormChecks])
                                                setTareasApply([...newFormApplies]);
                                            }}
                                        />
                                    </div>
                                </td>
                            }
                            {!showAplica &&
                                <td>
                                    <div className = "centerTextCell">
                                        <input
                                            className = "largeCheckBox"
                                            type = "checkbox"
                                            checked = {evaluacionComponenteResumenEtapaForm[index].check}
                                            disabled = {evaluacionComponenteResumenEtapaForm[index].disabled}
                                            onChange = {() => {
                                                let newForm = evaluacionComponenteResumenEtapaForm;
                                                newForm[index].check = !newForm[index].check;
                                                if (tareasApply) {
                                                    let applyForm = tareasApply;
                                                    if (newForm[index].check) {
                                                        applyForm[index].check = true;
                                                        applyForm[index].disabled = true;
                                                    } else {
                                                        applyForm[index].disabled = false;
                                                    }
                                                    setTareasApply([...applyForm]);
                                                }
                                                setEvaluacionComponenteResumenEtapaForm([...newForm]);
                                            }}
                                        />
                                    </div>
                                </td>
                            }
                        </tr>
                    ))}
                </tbody>
            </Table>
            {isEvaluacionNotComplete &&
                <div>
                    <small>
                        Para aplicar los listados "Trabajo por realizar", "TOT por realizar" e "Insumos" no debe haber ninguna tarea pendiente en las etapas anteriores
                    </small>
                </div>
            }
            <div className = "modal-buttons">
                <Button onClick = {submitResumen} variant = 'success' disabled = {isSaving}>
                    {isSaving ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                </Button>
                <Button onClick = {generalOnHide} variant = 'secondary' disabled = {isSaving}>
                    {isSaving ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                </Button>
            </div>
        </>
    )
}

export default EditEvaluacionReparacionComponenteResumen;