import cogoToast from "cogo-toast";
import { ReactElement, useContext, useEffect, useState } from "react"
import { Card, Spinner } from "react-bootstrap";
import { useParams } from "react-router";
import { getOrdenProceso } from "../api/OrdenesProcesoApi";
import { getReparacion } from "../api/ReparacionesApi";
import ReparacionDetails from "../components/reparaciones/ReparacionDetails";
import AuthContext from "../contexts/AuthContext";
import useBreadcrumb from "../hooks/useBreadcrumb";
import { OrdenProceso } from "../models/estatusGeneral/OrdenProceso";
import { Reparacion } from "../models/Reparaciones"
import { getUserPermissionFunction } from "../utils/users";
import DetallesEvaluacionReparacionApi from "../api/EvaluacionesReparacionesApi";
import { EvaluacionOrReparacionComponenteResumenEtapa } from "../models/EvaluacionesReparaciones";

const ReparacionPage = (): ReactElement => {

    const [ reparacion, setReparacion ] = useState<Reparacion>();
    const [ evaulacionReparacionComponenteResumenEtapas, setEvaulacionReparacionComponenteResumenEtapas ] = useState<EvaluacionOrReparacionComponenteResumenEtapa[]>();
    const [ ordenProceso, setOrdenProceso ] = useState<OrdenProceso>();
    const { codigoOP } = useParams<{ codigoOP: string }>();
    const { userRoles, userEmail, userName } = useContext(AuthContext);
    const breadcrumb = useBreadcrumb();
    const [ isLoading, setIsLoading ] = useState<boolean>(true);
    const detallesApi = new DetallesEvaluacionReparacionApi(codigoOP, true);

    useEffect(() => {
        if (reparacion) {
            breadcrumb.setItems([
                {
                    label: 'Estatus General',
                    href: '/ordenesProceso'
                },
                {
                    label: codigoOP,
                    href: `/ordenesProceso/${codigoOP}`
                },
                {
                    label: 'Reparación',
                    inactive: true
                }
            ]);
        }
    }, [reparacion])

    useEffect(() => {
        getReparacion(codigoOP)
            .then((response) => {
                if (response.data.fechaInicial) {
                    setReparacion(response.data);
                    getOrdenProceso(codigoOP)
                        .then((response) => {
                            if (response.data.codigoOP) {
                                setOrdenProceso(response.data);
                                detallesApi.getEvaluacionOrReparacionComponenteResumen()
                                    .then((response) => {
                                        if (response.data) {
                                            setEvaulacionReparacionComponenteResumenEtapas(response.data);
                                        }
                                    })
                                    .catch(() => {
                                        cogoToast.error('No fue posible obtener el detalle de la reparación')
                                    })
                            } else {
                                // cogoToast.error(response.data);
                                cogoToast.error('No fue posible obtener la orden de proceso asociada al componente');
                            }
                        })
                        .catch(() => {
                            // cogoToast.error(error);
                            cogoToast.error('No fue posible obtener la orden de proceso asociada al componente');
                        });
                } else {
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible obtener la reparación del componente');
                }
                setIsLoading(false);
            })
            .catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible obtener la reparación del componente');
            })
    }, [codigoOP]);

    const changeReparacion = (reparacion: Reparacion) => {
        setReparacion(reparacion);
    }

    if (!reparacion || !ordenProceso) {
        if (isLoading) {
            return (
                <Card>
                    <Card.Body className = "text-center">
                        <Spinner animation = "border"/>
                    </Card.Body>
                </Card>
            )
        } else {
            return <p className = "text-center">No fue posible cargar la reparación del componente</p>
        }
    } else {
        return (
            <>
                <ReparacionDetails
                    reparacion = {reparacion}
                    changeReparacion = {changeReparacion}
                    userPermissionFunction = {getUserPermissionFunction(userRoles, userEmail)}
                    evaulacionReparacionComponenteResumenEtapas = {evaulacionReparacionComponenteResumenEtapas}
                    ordenProceso = {ordenProceso}
                    userData = {{ userName: userName, userEmail: userEmail, userRoles: userRoles }}
                    detallesApi = {detallesApi}
                />
            </>
        )
    }
}

export default ReparacionPage;