import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { putOrdenProceso } from "../../api/OrdenesProcesoApi";
import useNumeroGalponOptions from "../../hooks/componentes/useNumeroGalpon";
import useTiposComponentesOptions from "../../hooks/componentes/useTiposComponentes";
import { ComponentesFilters, ComponenteTabla } from "../../models/componentes/Componente";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";
import { bodyCardsFields } from "../../utils/bodyCards";
import { datosComponenteOPArray, getEmptyComponentesFilters } from "../../utils/componentes";
import { fixUrlSpecialCharacters } from "../../utils/urls";
import ConfirmDeletionModal from "../form/ConfirmDeletionModal";
import ComponentesFiltersComponent from "./ComponentesFiltersComponent";
import ComponentesList from "./ComponentesList";
import usePlanificacionComponenteOptions from "../../hooks/componentes/usePlanificacionComponente";

interface AsignarComponenteProps {
    ordenProceso: OrdenProceso,
    onOrdenProcesoChange: (ordenProceso: OrdenProceso) => void,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean
}

const AsignarComponente = ({ ordenProceso, onOrdenProcesoChange, userPermissionFunction }: AsignarComponenteProps): ReactElement => {

    const editPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR'] });
    const viewPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR'] });

    const [componentesFilters, setComponentesFilters] = useState<ComponentesFilters>(
        getEmptyComponentesFilters()
    );

    const datosComponenteOPTHTML = bodyCardsFields(datosComponenteOPArray(ordenProceso));

    const history = useHistory();
    const tiposComponentesOptions = useTiposComponentesOptions();
    const numeroGalponOptions = useNumeroGalponOptions();
    // const planificacionComponenteOptions = usePlanificacionComponenteOptions();

    const [showAddComponentes, setShowAddComponentes] = useState(false);
    const handleShowAddComponentes = () => setShowAddComponentes(true);
    const handleCloseAddComponentes = () => setShowAddComponentes(false);

    const addComponenteToOrdenProceso = (componente: ComponenteTabla): void => {
        putOrdenProceso({ ...ordenProceso, componente: componente })
            .then((response) => {
                setShowAddComponentes(false);
                onOrdenProcesoChange(response.data);
                cogoToast.success('Componente añadido a Orden de Proceso');
            })
            .catch(() => {
                cogoToast.error('No fue posible añadir el componente a la Orden de proceso');
            });
    }

    const removeCurrentComponente = (setIsDeleting: () => void): void => {
        putOrdenProceso({ ...ordenProceso, componente: undefined })
            .then((response) => {
                onOrdenProcesoChange(response.data);
                setIsDeleting();
                cogoToast.success('Componente removido de Orden de Proceso');
            })
            .catch(() => {
                // cogoToast.error(error.message);
                setIsDeleting()
            });
    }

    const currentComponenteDetails = (): void => {
        const modelo = ordenProceso.componente?.modelo;
        const descripcion = ordenProceso.componente?.descripcion;
        if (modelo && descripcion) {
            history.push(`/ordenesProceso/${ordenProceso.codigoOP}/componente/${fixUrlSpecialCharacters(modelo)}/descripcion/${fixUrlSpecialCharacters(descripcion)}`);
        }
    }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        {ordenProceso.componente ? 'Componente' : 'Sin Componente'}
                    </div>
                    {ordenProceso.componente ?
                        <div className = "buttons-group">
                            {editPermission && !ordenProceso.recepcion && 
                                <ConfirmDeletionModal
                                    text = 'La orden de proceso se desvinculará del componente pero éste no se elimina'
                                    onDelete = {removeCurrentComponente}
                                />
                            }
                            {viewPermission &&
                                <Button onClick = {currentComponenteDetails} variant = "success">
                                    Detalles
                                </Button>
                            }
                        </div>
                    :
                        editPermission &&
                            <div className = "buttons-group">
                                <Button onClick = {handleShowAddComponentes}>
                                    Asignar Componente
                                </Button>
                            </div>
                    }
                </Card.Header>
                {ordenProceso.componente &&
                    (<Card.Body className = "cards-body-groups">
                        {datosComponenteOPTHTML}
                    </Card.Body>)
                }
            </Card>
            {showAddComponentes &&
                (<Modal show = {showAddComponentes} onHide = {handleCloseAddComponentes}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Componente asociado</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                            <ComponentesFiltersComponent 
                                tiposComponentesOptions = {tiposComponentesOptions}
                                numeroGalponOptions = {numeroGalponOptions}
                                componentesFilters = {componentesFilters}
                                onFiltersChange = {setComponentesFilters}
                                // planificacionComponenteOptions = {planificacionComponenteOptions}
                            />
                            <ComponentesList
                                componentesFilters = {componentesFilters}
                                setComponentesFilters = {setComponentesFilters}
                                addComponenteToOrdenProceso = {addComponenteToOrdenProceso}
                            />
                    </Modal.Body>
                </Modal>)
            }
        </>
    );
}

export default AsignarComponente;