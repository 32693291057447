import { ReactElement, useState } from "react";
import { TareaCheck } from "../../models/EvaluacionesReparaciones";
import EvaluacionReparacionComponenteDetallesChecks from "./EvaluacionReparacionComponenteDetallesChecks";
import { Button, Card, Col, Modal, Row, Spinner } from "react-bootstrap";

interface Props {
    isNotAvailable?: boolean
    nombreEtapa: string,
    vistoBueno: boolean,
    revisadoPor?: string,
    userPermission: boolean,
    isBusy: boolean,
    putEvaluacionOrReparacionComponenteResumen: (vistoBueno: boolean) => void
}

const EditControlCalidad = ({ isNotAvailable, nombreEtapa, vistoBueno, revisadoPor, userPermission, putEvaluacionOrReparacionComponenteResumen, isBusy }: Props): ReactElement => {

    const [ aprobacionState, setAprobacionState ] = useState<TareaCheck>({ name: '¿Aprobado?', check: vistoBueno, select: true });
    const [ show, setShow ] = useState<boolean>();
    const hide = () => setShow(false);

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        {nombreEtapa}
                    </div>
                    {userPermission && (
                        <div className = "buttons-group">
                            <Button onClick = {() => setShow(true)} variant="success" disabled={isNotAvailable}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Aprobación'}
                            </Button>
                        </div>
                    )}
                </Card.Header>
                <Card.Body className = "cards-body-groups">
                    <Row>
                        <Col>
                            Revisado por
                            <h3>{revisadoPor}</h3>
                        </Col>
                        <Col>
                            Visto Bueno
                            <h3>{vistoBueno ? 'SÍ' : 'NO'}</h3>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Modal show = {show} onHide = {hide}>
                <Modal.Header>
                    <Col className = "text-center">
                        <Modal.Title>Visto Bueno</Modal.Title>
                    </Col>
                </Modal.Header>
                <Modal.Body>
                    <EvaluacionReparacionComponenteDetallesChecks
                        tareaCheck = {aprobacionState}
                        isNotEditable = {isBusy}
                        changeAprobacionState = {(check: TareaCheck) => setAprobacionState(check)}
                    />
                    <div className = "modal-buttons">
                        <Button
                            onClick = {() => putEvaluacionOrReparacionComponenteResumen(aprobacionState.check)}
                            disabled = {isBusy}
                            variant = 'success'
                        >
                            {isBusy ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                        </Button>
                        <Button onClick = {hide} variant = 'secondary' disabled = {isBusy}>
                            {isBusy ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditControlCalidad;