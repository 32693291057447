import { SelectOption } from "../../models/optionsFilters/Select";
import { operariosRoles } from "../../utils/users";

const useRolUsuarioOptions = (
    task?: string
    //selected?: SelectOption<RolUsuario>[], select?: SelectOption<RolUsuario>
): SelectOption<string>[] => {
    const stringArrayToSelectOptionArray = (array: string[]): SelectOption<string>[] => array.map((element) => ({ label: element, value: element }));
    if (task === 'emails') {
        return ([
            { label: 'ADMINISTRADOR', value: 'ADMINISTRADOR' },
            { label: 'USUARIO', value: 'USUARIO' },
            { label: 'PLANIFICACIÓN', value: 'PLANIFICACION' },
            { label: 'JEFETALLER', value: 'JEFETALLER' },
            { label: 'FINANZAS', value: 'FINANZAS' },
            // { label: 'OPERARIO', value: 'OPERARIO' },
            // { label: 'CLIENTE', value: 'CLIENTE' }
            { label: 'CALIDAD', value: 'CALIDAD' },
            { label: 'SUPERVISOR', value: 'SUPERVISOR' }
        ]).concat(stringArrayToSelectOptionArray(operariosRoles));
    } else if (task === 'evaluacion') {
        return ([
            // { label: 'ADMINISTRADOR', value: 'ADMINISTRADOR' },
            // { label: 'USUARIO', value: 'USUARIO' },
            // { label: 'PLANIFICACIÓN', value: 'PLANIFICACION' },
            { label: 'JEFETALLER', value: 'JEFETALLER' },
            { label: 'SUPERVISOR', value: 'SUPERVISOR' }
            // { label: 'OPERARIO', value: 'OPERARIO' }
        ]).concat(stringArrayToSelectOptionArray(operariosRoles));
    } else if (task === 'controlCalidadList') {
        return ([
            // { label: 'ADMINISTRADOR', value: 'ADMINISTRADOR' },
            // { label: 'USUARIO', value: 'USUARIO' },
            // { label: 'PLANIFICACIÓN', value: 'PLANIFICACION' },
            // { label: 'JEFETALLER', value: 'JEFETALLER' }
            { label: 'CALIDAD', value: 'CALIDAD' },
            { label: 'SUPERVISOR', value: 'SUPERVISOR' }
            // { label: 'OPERARIO', value: 'OPERARIO' }
        ]);
    } else {
        return ([
            { label: 'ADMINISTRADOR', value: 'ADMINISTRADOR' },
            { label: 'USUARIO', value: 'USUARIO' },
            { label: 'PLANIFICACIÓN', value: 'PLANIFICACION' },
            { label: 'JEFETALLER', value: 'JEFETALLER' },
            { label: 'CALIDAD', value: 'CALIDAD' },
            { label: 'FINANZAS', value: 'FINANZAS' },
            // { label: 'OPERARIO', value: 'OPERARIO' },
            { label: 'CLIENTE', value: 'CLIENTE' },
            { label: 'SUPERVISOR', value: 'SUPERVISOR' }
        ]).concat(stringArrayToSelectOptionArray(operariosRoles));
    }

    /*
    if (!(!selected && !select)) {
        let optionsHidingSelected: SelectOption<RolUsuario>[] = [];
        if (!select && selected) {
            allOptions.map((option) => {
                if (!selected.includes(option)) {
                    optionsHidingSelected.push(option);
                }
            });
        } else if (select && !selected) {
            allOptions.map((option) => {
                if (select.label !== option.label) {
                    optionsHidingSelected.push(option);
                }
            });
        } else {
            if (select && selected?.includes(select)) {
                allOptions.map((option) => {
                    if (!selected.includes(option)) {
                        optionsHidingSelected.push(option);
                    } else if (option.label === select.label) {
                        optionsHidingSelected.push(option);
                    }
                });
            } else {
                allOptions.map((option) => {
                    if (!selected?.includes(option) && option.label !== select?.label) {
                        optionsHidingSelected.push(option);
                    }
                });
            }
        }
        return optionsHidingSelected;
    } else {
        return allOptions;
    }
    */
}

export default useRolUsuarioOptions;