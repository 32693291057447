import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { deleteOrdenProceso, putOrdenProceso } from "../../api/OrdenesProcesoApi";
import useFormState from "../../hooks/useFormState";
import { OrdenProceso, OrdenProcesoForm } from "../../models/estatusGeneral/OrdenProceso";
import { datosOPArray, formatOrdenProceso, formatOrdenProcesoForm } from "../../utils/estatusGeneral";
import AsignarComponente from "../componentes/AsignarComponente";
import OrdenProcesoFormComponent from "./OrdenProcesoFormComponent";
import RecepcionOPComponent from "../recepciones/RecepcionOPComponent";
import DetallesOP from "./DetallesOP";
import useTipoProceso from "../../hooks/estatusGeneral/useTipoProceso";
import useTipoOrdenProceso from "../../hooks/estatusGeneral/useTipoOrdenProceso";
import useCentroCostoProceso from "../../hooks/estatusGeneral/useCentroCostoProceso";
import useUENElectromecanico from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENElectromecanico";
import useUENPotencia from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENPotencia";
import useUENLaboratorio from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENLaboratorio";
import { SelectOption } from "../../models/optionsFilters/Select";
import { UnidadEstrategicaNegocio } from "../../models/estatusGeneral/UnidadEstrategicaNegocio";
import useUENMecanico from "../../hooks/estatusGeneral/unidadEstrategicaNegocio/useUENMecanico";
import EvaluacionOPComponent from "../evaluaciones/EvaluacionOPComponent";
import useEstadosOrdenProceso from "../../hooks/estatusGeneral/useEstadosOrdenProceso";
import ReparacionOPComponent from "../reparaciones/ReparacionOPComponent";
import EntregaOPComponent from "../entregas/EntregaOPComponent";
import { bodyCardsFields } from "../../utils/bodyCards";
import ObservacionesOPComponent from "./ObservacionesOPComponent";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import SubOPTab from "./SubOPTab";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";

interface OrdenProcesoDetailsProps {
    ordenProceso: OrdenProceso,
    onOrdenProcesoChange: (ordenProceso: OrdenProceso) => void,
    // _id?: string,
    userRoles: string[],
    currentOPIndex: number,
    userEmail: string,
    userName: string,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean
}

const OrdenProcesoDetails = ({ ordenProceso, onOrdenProcesoChange, userPermissionFunction, userRoles, currentOPIndex, userEmail, userName }: OrdenProcesoDetailsProps): ReactElement => {
    
    const [ selectedTab, setSelectedTab ] = useState<number>(currentOPIndex);
    const estadoOrdenProcesoOptions = useEstadosOrdenProceso(ordenProceso.estado, userRoles, ordenProceso.reparacion ? true : false, ordenProceso);
    const tipoProcesoOptions = useTipoProceso(ordenProceso.APP);
    const tipoOrdenProcesoOptions = useTipoOrdenProceso();
    const centroCostoProcesoOptions = useCentroCostoProceso();
    const uenElectromecanicoOptions = useUENElectromecanico();
    const uenPotenciaOptions = useUENPotencia();
    const uenLaboratorioOptions = useUENLaboratorio();
    const uenMecanicoOptions = useUENMecanico();
    const [ uenOptions, setUenOptions ] = useState<SelectOption<UnidadEstrategicaNegocio>[]>();
    const [ centroCostoAnterior, setCentroCostoAnterior ] = useState<string>('INICIAL');
    const datosOPHTML = bodyCardsFields(datosOPArray(ordenProceso));
    const generalEditPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR', 'JEFETALLER', 'SUPERVISOR', 'CALIDAD'] });
    const fieldsEditPermission = userPermissionFunction({ roles: ['FINANZAS'] });

    const onUenOptionsChange = (uens?: SelectOption<UnidadEstrategicaNegocio>[]): void => setUenOptions(uens);

    const onCentroCostoAnteriorChange = (cc: string): void => setCentroCostoAnterior(cc);

    const formStateOrdenProceso = useFormState(
        formatOrdenProcesoForm(
            ordenProceso,
            tipoOrdenProcesoOptions,
            tipoProcesoOptions,
            centroCostoProcesoOptions,
            uenElectromecanicoOptions,
            uenPotenciaOptions,
            uenLaboratorioOptions,
            uenMecanicoOptions,
            // estadoOrdenProcesoOptions
        )
    );

    const [showEditOP, setShowEditOP] = useState(false);
    const handleShowEditOP = () => setShowEditOP(true);
    const handleCloseEditOP = () => {
        formStateOrdenProceso.reset();
        setShowEditOP(false);
    };

    const history = useHistory();

    const submitForm = (ordenProcesoForm: OrdenProcesoForm, setIsEmailSending: (state: boolean) => void) => {
        putOrdenProceso(formatOrdenProceso(ordenProceso, ordenProcesoForm, undefined, undefined))
            .then(async (response) => {
                if (response.data.codigoOP) {
                    // if (ordenProceso.estado !== response.data.estado && response.data.estado === 'APROBADA' && ordenProceso.estado !== 'REPARADA') {
                    //     setIsEmailSending(true);
                    //     await getDestinatariosEntregaEvaluacionReparacion()
                    //         .then(async (responseDestinatarios) => {
                    //             await generalSendEmailInModal(
                    //                 getCorreoOrdenProcesoAprobada(response.data, responseDestinatarios.data.to, responseDestinatarios.data.cc),
                    //                 userName,
                    //                 userEmail,
                    //                 setIsEmailSending
                    //             )
                    //         }).catch(() => {
                    //             cogoToast.error('No fue posible adquirir los destinatarios para enviar el correo');
                    //         });
                    // }
                    setShowEditOP(false);
                    onOrdenProcesoChange(response.data);
                    formStateOrdenProceso.setForm(formatOrdenProcesoForm(
                        response.data,
                        tipoOrdenProcesoOptions,
                        tipoProcesoOptions,
                        centroCostoProcesoOptions,
                        uenElectromecanicoOptions,
                        uenPotenciaOptions,
                        uenLaboratorioOptions,
                        uenMecanicoOptions,
                        // estadoOrdenProcesoOptions
                    ));
                    cogoToast.success('Orden de proceso editada');
                } else {
                    // cogoToast.error(response.data.message);
                    cogoToast.error('No fue posible editar la orden de proceso');
                }
                formStateOrdenProceso.setSubmitting(false);
            })
            .catch(() => {
                // cogoToast.error(error.message);
                cogoToast.error('No fue posible editar la orden de proceso');
                formStateOrdenProceso.setSubmitting(false);
                formStateOrdenProceso.reset();
            })
    };

    const isDeleteable = (): boolean => {
        if (!ordenProceso.componente && (!ordenProceso.subOPs || ordenProceso.subOPs.length < 1)) {
            return true;
        } else {
            return false;
        }
    }

    const deleteCurrentOrdenProceso = (): void => {
        formStateOrdenProceso.setSubmitting(true);
        deleteOrdenProceso(ordenProceso.codigoOP)
            .then(() => {
                cogoToast.success('Orden de Proceso eliminada');
                history.push('/ordenesProceso');
            })
            .catch(() => {
                cogoToast.error('No fue posible eliminar la OP');
            });
    };

    const editSubOPs = (setState: () => void, subOP?: string): void => {
        let cogoMessageSuccess = 'Hecho';
        let cogoMessageError = 'Error';
        let ordenProcesoRequest = ordenProceso;
        if (!subOP) {
            cogoMessageSuccess = 'Sub orden de proceso añadida!';
            cogoMessageError = 'No fue posible añadir la Sub orden de proceso';
            ordenProcesoRequest = { ...ordenProcesoRequest, subOPs: ordenProceso.subOPs ? [...ordenProceso.subOPs, 'NUEVA'] : ['NUEVA'] }
        } else {
            cogoMessageSuccess = 'Sub orden de proceso eliminada!';
            cogoMessageError = 'No fue posible eliminar la sub orden de proceso';
            ordenProcesoRequest = { ...ordenProcesoRequest, subOPs: ordenProceso.subOPs?.map((codigoOP) => {
                if (subOP === codigoOP) {
                    return 'ELIMINADA';
                } else {
                    return codigoOP;
                }
            })}
        }
        putOrdenProceso(ordenProcesoRequest)
            .then((response) => {
                if (response.data.codigoOP) {
                    onOrdenProcesoChange(response.data);
                    cogoToast.success(cogoMessageSuccess);
                    if (subOP) {
                        if (response.data.subOPs) {
                            setSelectedTab(response.data.subOPs.length + 1);
                        } else {
                            setSelectedTab(1);
                        }
                    }
                } else {
                    cogoToast.error(cogoMessageError);
                }
                setState();
            })
            .catch(() => {
                cogoToast.error(cogoMessageError);
                setState();
            })
    }

    return (
        <Tabs selectedIndex = {selectedTab} onSelect = {(index) => setSelectedTab(index)}>
            <TabList>
                <Tab>
                    <h5>{ordenProceso.componente ? ordenProceso.componente.descripcion : ordenProceso.codigoOP}</h5>
                </Tab>
                {ordenProceso.subOPs && (
                    ordenProceso.subOPs.map((subOP) => (
                        <Tab>
                            <h5>{subOP}</h5>
                        </Tab>
                    ))
                )}
                {generalEditPermission && (
                    <Tab>
                        <div className = "react-tabs-new-tab">Agregar Sub-OP</div>
                    </Tab>
                )}
            </TabList>
            <TabPanel>
                <Card className = "cards-container">
                    <Card.Header className = "cards-header">
                        <div className = "body-title">
                            {ordenProceso.componente ? `${ordenProceso.componente.descripcion}` : `${ordenProceso.codigoOP}`}
                        </div>
                        {(generalEditPermission || fieldsEditPermission) &&
                            <div className = "buttons-group">
                                <Button onClick = {handleShowEditOP}>Editar OP</Button>
                            </div>
                        }
                    </Card.Header>
                    <Card.Body className = "cards-body-groups">
                        {datosOPHTML}
                    </Card.Body>
                </Card>
                <DetallesOP
                    ordenProceso = {ordenProceso}
                    onOrdenProcesoChange = {onOrdenProcesoChange}
                    // _id = {_id}
                    userPermission = {generalEditPermission || fieldsEditPermission}
                />
                <AsignarComponente
                    ordenProceso = {ordenProceso}
                    onOrdenProcesoChange = {onOrdenProcesoChange}
                    // _id = {_id}
                    userPermissionFunction = {userPermissionFunction}
                />
                {ordenProceso.componente && (
                    <RecepcionOPComponent
                        ordenProceso = {ordenProceso}
                        onOrdenProcesoChange = {onOrdenProcesoChange}
                        // _id = {_id}
                        userPermissionFunction = {userPermissionFunction}
                    />
                )}
                {ordenProceso.recepcion && (
                    <EvaluacionOPComponent
                        ordenProceso = {ordenProceso}
                        onOrdenProcesoChange = {onOrdenProcesoChange}
                        userPermissionFunction = {userPermissionFunction}
                    />
                )}
                {ordenProceso.evaluacion && (
                    <ReparacionOPComponent
                        ordenProceso = {ordenProceso}
                        onOrdenProcesoChange = {onOrdenProcesoChange}
                        userPermissionFunction = {userPermissionFunction}
                    />
                )}
                {ordenProceso.recepcion &&
                    // ordenProceso.reparacion &&
                    (<EntregaOPComponent
                        ordenProceso = {ordenProceso}
                        onOrdenProcesoChange = {onOrdenProcesoChange}
                        userPermissionFunction = {userPermissionFunction}
                    />)
                }
                <ObservacionesOPComponent
                    ordenProceso = {ordenProceso}
                    onOrdenProcesoChange = {onOrdenProcesoChange}
                    userPermission = {generalEditPermission || fieldsEditPermission}
                />
                {showEditOP && (
                    <Modal show = {showEditOP} onHide = {handleCloseEditOP}>
                        <Modal.Header>
                            <Col className = "text-center">
                                <Modal.Title>Editar OP</Modal.Title>
                            </Col>
                        </Modal.Header>
                        <Modal.Body>
                            <OrdenProcesoFormComponent
                                formState = {formStateOrdenProceso}
                                submitForm = {submitForm}
                                onCancel = {handleCloseEditOP}
                                onDelete = {isDeleteable() ? deleteCurrentOrdenProceso : undefined}
                                tipoOrdenProcesoOptions = {tipoOrdenProcesoOptions}
                                tipoProcesoOptions = {tipoProcesoOptions}
                                centroCostoProcesoOptions = {centroCostoProcesoOptions}
                                uenOptions = {uenOptions}
                                onUenOptionsChange = {onUenOptionsChange}
                                centroCostoAnterior = {centroCostoAnterior}
                                onCentroCostoAnteriorChange = {onCentroCostoAnteriorChange}
                                uenElectromecanicoOptions = {uenElectromecanicoOptions}
                                uenPotenciaOptions = {uenPotenciaOptions}
                                uenLaboratorioOptions = {uenLaboratorioOptions}
                                uenMecanicoOptions = {uenMecanicoOptions}
                                estadoOrdenProcesoOptions = {estadoOrdenProcesoOptions}
                                currentEstado = {ordenProceso.estado}
                            />
                        </Modal.Body>
                    </Modal>
                )}
            </TabPanel>
            {ordenProceso.subOPs && (
                ordenProceso.subOPs.map((subOP) => (
                    <TabPanel>
                        <SubOPTab
                            codigoOP = {subOP}
                            editSubOPs = {editSubOPs}
                            userRoles = {userRoles}
                            userPermissionFunction = {userPermissionFunction}
                            userName = {userName}
                            userEmail = {userEmail}
                        />
                    </TabPanel>
                ))
            )}
            {generalEditPermission && (
                <TabPanel>
                    <SubOPTab
                        editSubOPs = {editSubOPs}
                    />
                </TabPanel>
            )}
        </Tabs>
    );
}

export default OrdenProcesoDetails;