import cogoToast from "cogo-toast";
import { ReactElement, useEffect, useState } from "react";
import { Button, Col, Modal, Spinner } from "react-bootstrap";
import { getImagenProceso } from "../../api/DocumentosApi";
import { getOrdenProceso } from "../../api/OrdenesProcesoApi";
import { ProcesoPhotoRequest } from "../../models/Archivos";
import { generarInformeEvaluacionComponente } from "../../utils/evaluaciones/evaluaciones";
import EvaluacionReparacionComponenteDetallesChecks from "./EvaluacionReparacionComponenteDetallesChecks";
import { EvaluacionOrReparacionComponenteEtapa, TareaCheck, TareaStatus } from "../../models/EvaluacionesReparaciones";
import DetallesEvaluacionReparacionApi from "../../api/EvaluacionesReparacionesApi";

interface Props {
    codigoOP: string,
    evaluacionStatus: TareaStatus[],
    isNotEditable: boolean,
    changeEvaluacionReparacionComponenteEtapa: (evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa) => void,
    evaluacionReparacionComponenteEtapa: EvaluacionOrReparacionComponenteEtapa,
    nombreTarea: string,
    isBusy: boolean,
    changeIsBusy: (state: boolean) => void,
    updateEvaluacionReparacionComponenteEtapaTarea: (hideModal: () => void) => void,
    detallesApi: DetallesEvaluacionReparacionApi
};

const EvaluacionReparacionComponenteDetallesGenerarInforme = ({
    evaluacionStatus,
    isNotEditable,
    changeEvaluacionReparacionComponenteEtapa,
    evaluacionReparacionComponenteEtapa,
    nombreTarea,
    isBusy,
    changeIsBusy,
    updateEvaluacionReparacionComponenteEtapaTarea,
    codigoOP,
    detallesApi
}: Props): ReactElement => {

    const getAprobacionState = (): TareaCheck => {
        const response: TareaCheck = { name: '¿Aprobado?', check: false, select: true };
        const currentState = evaluacionReparacionComponenteEtapa.tareas.find((tarea) => tarea.nombre === nombreTarea)?.check;
        if (currentState && currentState.check) {
            response.check = true;
        }
        return response;
    }
    
    const [ isDownloadingImages, setIsDownloadingImages ] = useState<boolean>(false);
    const [ aprobacionState, setAprobacionState ] = useState<TareaCheck>(getAprobacionState());
    const [ show, setShow ] = useState<boolean>(false);
    const hideModal = () => setShow(false);
    const etapasUncomplete = ((): ReactElement[] => {
        const response: { etapa: string, tareasMissing: string[] }[] = [];
        evaluacionStatus.forEach((etapaStatus) => {
            if (!etapaStatus.isComplete && etapaStatus.tareasMissing.length > 0) {
                response.push({ etapa: etapaStatus.etapa, tareasMissing: etapaStatus.tareasMissing });
            }
        });
        return response.map((element) => (
            <>
                <li>
                    {element.etapa}
                </li>
                <ul>
                    {element.tareasMissing.map((tareaMissing) => (
                        <li>{tareaMissing}</li>
                    ))}
                </ul>
            </>
        ));
    })();
    const areEtapasUncomplete = etapasUncomplete.length > 0 ? true : false;
    const isInformeNotBuildable = !evaluacionReparacionComponenteEtapa.isControlCalidadComplete || areEtapasUncomplete || isBusy;

    const buildInformeEvaluacion = () => {
        changeIsBusy(true);
        getOrdenProceso(codigoOP)
            .then((ordenProcesoResponse) => {
                if (ordenProcesoResponse.data) {
                    detallesApi.getEvaluacionOrReparacionComponenteEtapas()
                        .then(async (response) => {
                            if (response.data && response.data.length > 0) {
                                const photos: ProcesoPhotoRequest[] = [];
                                response.data.forEach((etapa) => etapa.tareas.forEach((tarea) => tarea.photos?.forEach((photo) => photos.push({
                                    nombreVariable: photo.title,
                                    codigoOP: codigoOP,
                                    nombreProceso: 'evaluacion',
                                    nombreEtapa: etapa.nombreEtapa,
                                    nombreTarea: tarea.nombre
                                }))));
                                for (let i = 0; i < photos.length; i++) {
                                    setIsDownloadingImages(true);
                                    await getImagenProceso(photos[i])
                                        .then((photoResponse) => {
                                            if (photoResponse.data) {
                                                photos[i].imagen = photoResponse.data;
                                            }
                                        })
                                        .catch(() => {
                                            cogoToast.error(`No fue posible obtener la imagen ${photos[i].nombreVariable}`);
                                        })
                                }
                                setIsDownloadingImages(false);
                                try {
                                    generarInformeEvaluacionComponente(ordenProcesoResponse.data, evaluacionReparacionComponenteEtapa, response.data, photos, true);
                                } catch (error) {
                                    console.log(error)
                                }
                                cogoToast.success('Informe generado');
                                changeIsBusy(false);
                            } else {
                                changeIsBusy(false);
                                cogoToast.error('No fue posible generar el informe');
                            }
                        })
                        .catch((error) => {
                            changeIsBusy(false);
                            console.log(error)
                            cogoToast.error('No fue posible generar el informe');
                        })
                } else {
                    changeIsBusy(false);
                    cogoToast.error('No fue posible generar el informe');
                }
            })
            .catch(() => {
                changeIsBusy(false);
                cogoToast.error('No fue posible generar el informe');
            })
    }
    
    useEffect(() => {
        setAprobacionState(getAprobacionState());
    }, [show]);

    return (
        <>
            <Button onClick = {() => setShow(true)} disabled = {isNotEditable}>
                {!isBusy ? 'Revisión' : <Spinner animation = "border" size = "sm"/>}
            </Button>
            {show &&
                <Modal show = {show} onHide = {hideModal}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Informe de Evaluación</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body className = "cards-body-groups">
                        {aprobacionState && 
                            <EvaluacionReparacionComponenteDetallesChecks
                                tareaCheck = {aprobacionState}
                                isNotEditable = {isInformeNotBuildable}
                                // changeEvaluacionComponenteEtapa = {changeEvaluacionComponenteEtapa}
                                evaluacionReparacionComponenteEtapa = {evaluacionReparacionComponenteEtapa}
                                nombreTarea = {nombreTarea}
                                changeAprobacionState = {(check: TareaCheck) => setAprobacionState(check)}
                            />
                        }
                        <div>
                            <h5>Tareas incompletas:</h5>
                            {areEtapasUncomplete ? <ul>{etapasUncomplete}</ul> : <small>No hay tareas incompletas</small>}
                            {!evaluacionReparacionComponenteEtapa.isControlCalidadComplete && <small>Hay controles de calidad que no han sido aprobados</small>}
                        </div>
                        {isDownloadingImages &&
                            <div>
                                ... Descargando fotos <Spinner animation="border" size="sm" />
                            </div>
                        }
                        <div className = "modal-buttons">
                            <Button onClick = {buildInformeEvaluacion} disabled = {isInformeNotBuildable}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Vista previa'}
                            </Button>
                            <Button
                                onClick = {() => {
                                    changeEvaluacionReparacionComponenteEtapa({ ...evaluacionReparacionComponenteEtapa, tareas: evaluacionReparacionComponenteEtapa.tareas.map((tarea) => {
                                        let response = tarea;
                                        if (response.nombre === nombreTarea) {
                                            response.check = aprobacionState;
                                        }
                                        return response;
                                    })});
                                    updateEvaluacionReparacionComponenteEtapaTarea(hideModal);
                                    window.location.reload();
                                }}
                                variant = 'success'
                                disabled = {isInformeNotBuildable}
                            >
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Guardar'}
                            </Button>
                            <Button onClick = {hideModal} variant = 'secondary' disabled = {isBusy}>
                                {isBusy ? <Spinner animation="border" size="sm" /> : 'Cancelar'}
                            </Button>
                        </div>
                    </Modal.Body>
                </Modal>
            }
        </>
    );
};

export default EvaluacionReparacionComponenteDetallesGenerarInforme;