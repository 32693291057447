import { ReactElement } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import Layout from './components/layout/Layout'
import AppRoute from './components/route/AppRoute';
import HomePage from './pages/HomePage';
import AuthStore from './store/AuthStore';
import LoginPage from './pages/users/LoginPage';
import UsersPage from './pages/users/UsersPage';
import UserPage from './pages/users/UserPage';
import QRComponentesPage from './pages/componentes/QRComponentesPage';
import QRComponenteReparadoPage from './pages/componentes/QRComponenteReparadoPage';
import ComponentePage from './pages/componentes/ComponentePage';
import ComponentesPage from './pages/componentes/ComponentesPage';
import EstatusGeneralPage from './pages/estatusGeneral/EstatusGeneralPage';
import OrdenProcesoPage from './pages/estatusGeneral/OrdenProcesoPage';
import RecepcionPage from './pages/recepciones/RecepcionPage';
import AperturaOPPage from './pages/AperturaOPPage';
// import RecepcionesPage from './pages/recepciones/RecepcionesPage';
import BackupPage from './pages/BackupPage';
import EvaluacionPage from './pages/EvaluacionPage';
import ReparacionPage from './pages/ReparacionPage';
import EntregaPage from './pages/EntregaPage';
import EvaluacionReparacionComponenteDetallesEtapaPage from './pages/EvaluacionReparacionComponenteDetallesEtapaPage';
import ConfiguracionesPage from './pages/ConfiguracionesPage';

const App = (): ReactElement => (
    <BrowserRouter>
        <AuthStore>
            <Switch>
                <Route exact path = "/login">
                    <LoginPage />
                </Route>
                <Route>
                    <Layout>
                        <Switch>
                            <AppRoute title = "Componentes - Flujo de Procesos" exact path = "/">
                                <HomePage />
                            </AppRoute>
                            <AppRoute title = "Administración de usuarios" exact path = "/users">
                                <UsersPage />
                            </AppRoute>
                            <AppRoute title = "Usuario" exact path = "/users/:email">
                                <UserPage />
                            </AppRoute>
                            <AppRoute title = "Usuario" exact path = "/users/:currentUser/:email">
                                <UserPage />
                            </AppRoute>
                            <AppRoute title = "Componente" exact path = "/componentes/:modeloComponente/descripcion/:descripcionComponente">
                                <ComponentePage />
                            </AppRoute>
                            <AppRoute title = "Componentes" exact path = "/componentes">
                                <ComponentesPage />
                            </AppRoute>
                             <AppRoute title = "Respaldo de Archivos" exact path = '/backup'>
                                <BackupPage />
                            </AppRoute>
                            <AppRoute title = "Componente" exact path = '/ordenesProceso/:codigoOP/componente/:modeloComponente/descripcion/:descripcionComponente'>
                                <ComponentePage />
                            </AppRoute>
                            <AppRoute title = "Estatus General" exact path = "/ordenesProceso">
                                <EstatusGeneralPage />
                            </AppRoute>
                            <AppRoute title = "Orden de Proceso" exact path = '/ordenesProceso/:codigoOP'>
                               <OrdenProcesoPage />
                            </AppRoute>
                            {/* <AppRoute title = "Orden de Proceso" exact path = '/recepciones/:_id/:codigoOP'>
                                <OrdenProcesoPage />
                            </AppRoute> */}
                            <AppRoute title = "Recepción" exact path = '/ordenesProceso/:codigoOP/recepcion'>
                                <RecepcionPage />
                            </AppRoute>
                            {/* <AppRoute title = "Recepciones" exact path = '/recepciones'>
                                <RecepcionesPage />
                            </AppRoute>
                            <AppRoute title = "Recepción" exact path = '/recepciones/:_id'>
                                <RecepcionPage />
                            </AppRoute> */}
                            <AppRoute title = "Apertura de OPs" exact path = '/aperturaOP'>
                                <AperturaOPPage />
                            </AppRoute>
                            <AppRoute title = "Código QR de Componentes Reparados" exact path = '/componentesQR'>
                                <QRComponentesPage />
                            </AppRoute>
                            <AppRoute title = "Componente Reparado" exact path = '/componentesQR/:_id'>
                                <QRComponenteReparadoPage />
                            </AppRoute>
                            <AppRoute title = "Evaluación de componente" exact path = '/ordenesProceso/:codigoOP/evaluacion'>
                                <EvaluacionPage />
                            </AppRoute>
                            <AppRoute title = "Etapa de evaluación" exact path = '/ordenesProceso/:codigoOP/evaluacion/:etapa'>
                                <EvaluacionReparacionComponenteDetallesEtapaPage />
                            </AppRoute>
                            <AppRoute title = "Reparación de componente" exact path = '/ordenesProceso/:codigoOP/reparacion'>
                                <ReparacionPage />
                            </AppRoute>
                            <AppRoute title = "Etapa de reparación" exact path = '/ordenesProceso/:codigoOP/reparacion/:etapa'>
                                <EvaluacionReparacionComponenteDetallesEtapaPage />
                            </AppRoute>
                            <AppRoute title = "Entrega de componente" exact path = '/ordenesProceso/:codigoOP/entrega'>
                                <EntregaPage />
                            </AppRoute>
                            <AppRoute title = "Configuraciones" exact path = '/settingsPage'>
                                <ConfiguracionesPage />
                            </AppRoute>
                        </Switch>
                    </Layout>
                </Route>
            </Switch>
        </AuthStore>
    </BrowserRouter>
)

export default App;
