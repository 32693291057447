import MenuBarItem from "../models/layout/MenuBarItemModel";

const menubarItems: MenuBarItem[] = [
    {
        name: 'Componentes',
        href: '/componentes',
        roles: ['ADMINISTRADOR', 'PLANIFICACION', 'USUARIO', 'JEFETALLER', 'CALIDAD', 'SUPERVISOR']
    },
    {
        name: 'Estatus general',
        href: '/ordenesProceso',
        roles: ['ADMINISTRADOR', 'PLANIFICACION', 'USUARIO', 'JEFETALLER', 'OPERARIO', 'CALIDAD', 'FINANZAS', 'SUPERVISOR']
    },
    /*
    {
        name: 'Recepciones',
        href: '/recepciones',
        roles: ['ADMINISTRADOR', 'PLANIFICACION', 'USUARIO']
    },
    */
    {
        name: 'Apertura de OPs',
        href: '/aperturaOP',
        roles: ['ADMINISTRADOR', 'PLANIFICACION']
    },
    {
        name: 'Respaldo',
        href: '/backup',
        roles: ['ADMINISTRADOR', 'PLANIFICACION', 'CALIDAD']
    },
    {
        name: 'Administración de usuarios',
        href: '/users',
        roles: ['ADMINISTRADOR']
    },
    {
        name: 'QR Componentes reparados',
        href: '/componentesQR',
        roles: ['ADMINISTRADOR', 'PLANIFICACION', 'CALIDAD']
    },
    {
        name: 'Configuraciones',
        href: '/settingsPage',
        roles: ['ADMINISTRADOR', 'JEFETALLER', 'SUPERVISOR']
    }
];

export default function getMenuBarItems(userRol: string[]): MenuBarItem[] {
    return menubarItems.filter((item) => 
        !item.roles || userRol.some((rol) => {
            if (rol.includes('OPERARIO')) {
                return item.roles?.includes('OPERARIO');
            } else {
                return item.roles?.includes(rol);
            }
        }
    ));
}
