import cogoToast from "cogo-toast";
import { ReactElement, useState } from "react";
import { Button, Card, Col, Modal, Row } from "react-bootstrap";
import { OrdenProceso } from "../../models/estatusGeneral/OrdenProceso";
import ConfirmDeletionModal from "../form/ConfirmDeletionModal";
import { useHistory } from "react-router-dom";
import useFormState from "../../hooks/useFormState";
import { formatReparacionRequestBody, getEmptyReparacionForm } from "../../utils/reparaciones";
import ReparacionFormComponent from "./ReparacionFormComponent";
import { ReparacionForm } from "../../models/Reparaciones";
import { deleteReparacion, postReparacion } from "../../api/ReparacionesApi";
import useTOTOptions from "../../hooks/useTOTOptions";
import { AcceptedRolesElements, AcceptedUsersElements } from "../../models/users/Users";

interface ReparacionOPComponentProps {
    ordenProceso: OrdenProceso,
    onOrdenProcesoChange: (ordenProceso: OrdenProceso) => void,
    userPermissionFunction: (acceptedRolesElements?: AcceptedRolesElements, acceptedUsersElements?: AcceptedUsersElements) => boolean
}

const ReparacionOPComponent = ({ ordenProceso, onOrdenProcesoChange, userPermissionFunction }: ReparacionOPComponentProps): ReactElement => {
    
    const editPermission = userPermissionFunction({ roles: ['JEFETALLER', 'ADMINISTRADOR'] });
    const viewPermission = userPermissionFunction({ roles: ['PLANIFICACION', 'ADMINISTRADOR', 'JEFETALLER', 'OPERARIO', 'CALIDAD', 'SUPERVISOR'], notExactRol: true });

    const formState = useFormState(getEmptyReparacionForm());
    const history = useHistory();
    const totOptions = useTOTOptions();

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        formState.reset();
        setShow(false);
    };

    const canItBeAdded = (): boolean => {
        if (ordenProceso.estado !== 'NUEVA' &&
            ordenProceso.estado !== 'RECEPCIONADA' &&
            ordenProceso.estado !== 'EVALUADA' &&
            ordenProceso.estado !== 'PRESUPUESTADA' &&
            ordenProceso.estado !== 'CERRADA' &&
            ordenProceso.evaluacion?.fechaFinal) {
                return true;
            } else {
                return false;
            }
    }

    const submitForm = (reparacionForm: ReparacionForm) => {
        postReparacion(formatReparacionRequestBody(reparacionForm), ordenProceso.codigoOP)
            .then((response) => {
                if (response.data.codigoOP) {
                    formState.setSubmitting(false);
                    setShow(false);
                    onOrdenProcesoChange(response.data);
                    cogoToast.success('Reparación de componente añadida');
                    history.replace(`/ordenesProceso/${response.data.codigoOP}/reparacion`);
                } else {
                    formState.setSubmitting(false);
                    // cogoToast.error(response.data);
                    cogoToast.error('No fue posible agregar la reparación');
                }
            })
            .catch(() => {
                formState.setSubmitting(false);
                // cogoToast.error(error);
                cogoToast.error('No fue posible agregar la reparación');
            })
    }

    const deleteCurrentReparacion = (setIsDeleting: () => void) => {
        deleteReparacion(ordenProceso.codigoOP)
            .then((response) => {
                if (response.data.codigoOP) {
                    onOrdenProcesoChange(response.data);
                    cogoToast.success('Reparación eliminada!');
                } else {
                    cogoToast.error('No fue posible eliminar la reparación');
                }
                setIsDeleting();
            }).catch(() => {
                // cogoToast.error(error);
                cogoToast.error('No fue posible eliminar la reparación');
                setIsDeleting();
            })
    }

    const showReparacion = () => {
        history.push(`/ordenesProceso/${ordenProceso.codigoOP}/reparacion`)
    }

    return (
        <>
            <Card className = "cards-container">
                <Card.Header className = "cards-header">
                    <div className = "body-subtitle">
                        {ordenProceso.reparacion ? 'Reparación' : 'Sin reparación'}
                    </div>
                    {ordenProceso.reparacion ?
                        <div className = "buttons-group">
                            {editPermission && !ordenProceso.entrega && ordenProceso.estado === 'APROBADA' && (
                                <ConfirmDeletionModal
                                    text = 'Si elimina la reparación también se borrarán los informes asociados'
                                    onDelete = {deleteCurrentReparacion}
                                />
                            )}
                            {viewPermission &&
                                <Button onClick = {showReparacion} variant = "success" className = "body-buttons">
                                    Detalles
                                </Button>
                            }
                        </div>
                    :
                        editPermission &&
                            <div className = "buttons-group">
                                <Button onClick = {handleShow} disabled = {!canItBeAdded()}>
                                    Agregar Reparación
                                </Button>
                            </div>
                    }
                </Card.Header>
                {ordenProceso.reparacion && (
                    <Card.Body>
                        <Row>
                            <Col>
                                <u>Fecha inicial</u>
                                <h3>{ordenProceso.reparacion.fechaInicial}</h3>
                            </Col>
                            <Col>
                                <u>Fecha final</u>
                                <h3>{ordenProceso.reparacion.fechaFinal}</h3>
                            </Col>
                            <Col>
                                <u>T.O.T</u>
                                <h3>{ordenProceso.reparacion.tot}</h3>
                            </Col>
                        </Row>
                    </Card.Body>
                )}
            </Card>
            {show && ordenProceso.evaluacion?.fechaFinal &&
                (<Modal show = {show} onHide = {handleClose}>
                    <Modal.Header>
                        <Col className = "text-center">
                            <Modal.Title>Reparación de componente</Modal.Title>
                        </Col>
                    </Modal.Header>
                    <Modal.Body>
                        <ReparacionFormComponent
                            formState = {formState}
                            submitForm = {submitForm}
                            onCancel = {handleClose}
                            totOptions = {totOptions}
                            minDateReparacion = {ordenProceso.evaluacion.fechaFinal}
                            withArmadoReparacion = {ordenProceso.desarmeEvaluacionComponente}
                            isCreating
                        />
                    </Modal.Body>
                </Modal>
            )}
        </>
    )
}

export default ReparacionOPComponent;